<template>
    <b-overlay :show="showLoader">
        <b-card>
            <h2 style="font-weight: bold;">Izpolnite podatke</h2>
            <validation-observer ref="validate">
                <b-form class="form mt-1">
                    <BulletinBoard v-model="object"/>
                    <b-form-group class="text-right">
                        <b-button variant="secondary" @click.prevent="validationForm">UREDI OGLAS</b-button>
                    </b-form-group>
                </b-form>
            </validation-observer>
            <b-row class="mt-2" v-if="object.documents.length > 0">
                <b-col cols="12" md="6">
                    <h3>Dokumenti, ki so že shranjeni</h3>
                    <b-form-checkbox-group v-model="documentsToDelete">
                        <b-row v-for="(item, index) of object.documents" :key="'datoteka_'+index">
                            <b-col cols="11">
                                <a :href="'/api/management/v1/file/' + item">{{item.split('_')[1]}}</a>
                            </b-col>
                            <b-col cols="1">
                                <b-form-checkbox :value="item">Odstrani</b-form-checkbox>
                            </b-col>
                        </b-row>
                    </b-form-checkbox-group>
                </b-col>
            </b-row>
        </b-card>
    </b-overlay>
</template>

<script>
    import { ValidationObserver } from 'vee-validate'
    import BulletinBoard from '@/views/Components/BulletinBoard'
    import {BButton, BCard, BForm, BFormCheckbox, BFormCheckboxGroup, BFormGroup, BOverlay, BRow, BCol} from 'bootstrap-vue'

    export default {
        components: {
            BulletinBoard,
            ValidationObserver,
            BFormCheckbox,
            BFormCheckboxGroup,
            BOverlay,
            BCard,
            BForm,
            BFormGroup,
            BButton,
            BRow,
            BCol
        },

        data() {
            return {
                object: {
                    title:'',
                    content:'',
                    documents: [],
                    managers: [],
                    active: true,
                    files: [],
                    buildings: [],
                    buildingsSifre: [],
                    limitToBuildings: false
                },
                showLoader: false,
                documentsToDelete: []
            }
        },
        methods: {
            loadData() {
                const thisIns = this
                thisIns.showLoader = true
                const loadPromise = this.$http.get(`/api/management/v1/bulletin/${thisIns.$route.params.bulletin_id}`)
                loadPromise.then(function(response) {
                    thisIns.object = response.data
                    thisIns.object['files'] = []
                }).catch(function(error) {
                    thisIns.$printError(error.response.data)
                }).finally(function() {
                    thisIns.showLoader = false
                })
            },
            async validationForm() {
                this.$refs.validate.validate().then(async(success) => {
                    if (success) {
                        await this.editBulletin()
                    }
                })

            },
            async editBulletin() {
                this.showLoader = true
                try {

                    if (this.object.files.length > 0) {
                        this.object.documents = await this.uploadDocuments()
                    }


                    // from object.documents remove documents, which user selected for deletion
                    await this.filterDocumentsFromBulletin()

                    this.object.buildingsSifre = this.object.buildings.map(function(v) { return v.split(' | ') })
                    if (this.object.buildingsSifre !== null) {
                        this.object.buildingsSifre = this.object.buildingsSifre.map(item => item[item.length - 1])
                    }

                    const payload = {
                        'title':this.object.title,
                        'content':this.object.content,
                        'documents': this.object.documents,
                        'managers': this.object.managers,
                        'active': this.object.active,
                        'files': this.object.files,
                        'buildings': this.object.buildingsSifre,
                        'limitToBuildings': this.object.limitToBuildings
                    }

                    await this.$http.patch(`/api/management/v1/bulletin/${this.$route.params.bulletin_id}`, payload)
                    this.$printSuccess('Obvestilo je bilo uspešno urejeno')
                    await this.$router.push({name: 'bulletin-board'})
                } catch (error) {
                    this.$printError(error.response.data)
                } finally {
                    this.showLoader = false
                }
            },
            async uploadDocuments() {
                try {
                    const documents = []

                    const promises = []

                    for (const file of this.object.files) {
                        const formData = new FormData()
                        formData.append('file', file)

                        const loadPromise = await this.$http.post('/api/management/v1/file/', formData, {headers: {'Content-Type': 'multipart/form-data'}})
                        promises.push(loadPromise)
                    }
                    const results = await Promise.allSettled(promises)
                    for (const res of results) {
                        if (res.status === 'fulfilled') {
                            documents.push(res.value.data)
                        } else {
                            this.$printError('Napaka pri nalaganju dokumentov')
                        }
                    }
                    return documents
                } catch (error) {
                    this.$printError(error.response.data)
                }
            },
            filterDocumentsFromBulletin() {
                if (!this.documentsToDelete || !this.object.documents) return

                for (const item of this.object.documents) {
                    if (this.documentsToDelete.includes(item)) {
                        this.object.documents.splice(item, 1)
                    }
                }
            }
        },
        created() {
            this.loadData()
        }
    }
</script>
